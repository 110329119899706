import React from 'react'
import {Box, Chip, Radio, Typography} from '@mui/material'
import {useParams} from "react-router-dom"

const ShowChallenge = () => {
    const { challenge } = useParams()
    const decodedChallenge = decodeURIComponent(challenge)
    const parsedChallenge = JSON.parse(decodedChallenge)

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh', width: '90vw'}}>
            <div
                style={{
                    padding: '20px',
                    borderBottom: '1px solid #ddd',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h4">Challenge</Typography>
            </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '20px'}}>
                    <Typography variant="body1">{parsedChallenge.title}</Typography>
                    <Box sx={{padding: '8px', border: '1px solid #ddd'}}>
                        {parsedChallenge.tags.map((tag, index) => (
                            <Chip key={index} label={tag} style={{margin: 2}}/>
                        ))}
                    </Box>
                    {
                        parsedChallenge.data.choices.map((choice) => {
                            return <Box display="flex" alignItems="center">
                                <Radio size="small" value={0} disabled/>
                                <Typography variant="body1">{choice}</Typography>
                            </Box>
                        })
                    }
                </div>
            </div>
            )
            }

            export default ShowChallenge
