import React, {useContext, useEffect, useState} from 'react'
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material'
import {useNavigate} from "react-router-dom"
import {DataGrid} from '@mui/x-data-grid'
import {getAllChallenges, getMyChallenges} from "./utils/ServerAPI"
import {AuthContext} from "./components/auth/AuthProvider"
import TagsSelection from "./components/TagsSelection"
import ClearIcon from '@mui/icons-material/Clear'

const Challenges = () => {
    const navigate = useNavigate()
    const [rows, setRows] = useState([])
    const [tags, setTags] = useState([])
    const [searchString, setSearchString] = useState("")
    const [debouncedSearchString, setDebouncedSearchString] = useState(searchString)
    const [loading, setLoading] = useState(false)
    const { currentUser, isAdmin } = useContext(AuthContext)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchString(searchString)
        }, 1000)

        return () => {
            clearTimeout(handler)
        }
    }, [searchString])

    useEffect(() => {
        const fetchData = async () => {
            setRows([])
            // setLoading(true) TODO - not centered
            const getChallenges = (isAdmin) ? getAllChallenges : getMyChallenges
            getChallenges(tags.join(","), searchString, function(error, data) {
                setLoading(false)
                if (error) {
                    console.error("Failed to get challenges: " + JSON.stringify(error));
                    if (error.data && error.data.code === "401") {
                        navigate("/signin")
                    }
                } else {
                    setRows([])
                    if (data.data.challenges && data.data.challenges.length > 0) {
                        data.data.challenges.forEach((item) => {
                            item.id = item._id
                            if (item.type === "multiple-choice") {
                                item.choices = item.data.choices
                                item.correctAnswer = item.data.correctAnswer
                                setRows(challengeList => [...challengeList, item])
                            } else if (item.type === "free-form") {
                                item.answer = item.data.answer.text
                                setRows(challengeList => [...challengeList, item])
                            }
                        })
                    }
                }
            })
        }

        if (currentUser) {
            fetchData().then()
        }
    }, [debouncedSearchString, tags, currentUser])

    const handleSearchStringUpdate = (event) => {
        setSearchString(event.target.value)
    }

    const handleClear = (event) => {
        event.preventDefault()
        setSearchString("")
    }

    const addHandler = () => {
        navigate(`/add-challenge`)
    }

    const editHandler = (params) => {
        const data = encodeURIComponent(JSON.stringify(params.row))
        console.log("Edit challenge: " + JSON.stringify(params.row))
        if (currentUser.uid === params.row.uid || isAdmin) {
            navigate(`/edit-challenge/${data}`)
        } else {
            navigate(`/show-challenge/${data}`)
        }
    }

    const columns = [
        { field: 'title', headerName: 'Title', width: 350 },
        { field: 'type', headerName: 'Type', width: 100 },
        { field: 'choices', headerName: 'Choices / Answers', width: 350, renderCell: (params) => {
                return getAnswer(params)
            }
        },
        { field: 'correctAnswer', headerName: 'Answer', headerClassName: 'header-cell-wrap', width: 70 },
        { field: 'level', headerName: 'Level', width: 70 },
        { field: 'tags', headerName: 'Tags', width: 200, renderCell: (params) => {
            return (
                <Box>
                    {params.value.map((tag, index) => (
                        <Chip key={index} label={tag} style={{ margin: 2 }} />
                    ))}
                </Box>)
            }
        },
    ];
    const [pageSize, setPageSize] = React.useState(5); // Control the page size via state

    return (
        <div className="wrapper">
            {loading && (
                <div className="overlay">
                    <CircularProgress/>
                </div>
            )}

            <div className="content">
                <Container maxWidth="lg">
                    <Box mb={2} sx={{paddingTop: '20px'}}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4">
                                Challenges
                            </Typography>
                            <Button variant="contained" color="primary" onClick={addHandler}>
                                Add Challenge
                            </Button>
                        </Box>
                    </Box>

                    <TextField
                        sx={{paddingBottom: '10px'}}
                        fullWidth
                        variant="outlined"
                        label="Search" value={searchString} onChange={handleSearchStringUpdate}
                        slotProps={{
                            input: {
                                endAdornment: searchString && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClear}>
                                            <ClearIcon sx={{fontSize: 20}}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />

                    <TagsSelection
                        acceptAny={false}
                        onTagsChange={(tags) => {
                            console.log("Selected Tags:", tags)
                            setTags(tags)
                        }}
                    />

                    <Box display="flex" justifyContent="flex-end" mb={2} sx={{paddingTop: '16px'}}>
                        <div style={{height: '70vh', width: '100%'}}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pagination
                                pageSize={pageSize} // Set default page size
                                rowsPerPageOptions={[5, 10, 25]}
                                onRowClick={editHandler}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} // Handle page size change
                                paginationMode="client" // Explicit pagination mode (client or server)
                                getRowHeight={() => 'auto'}
                                sx={{
                                    '& .MuiDataGrid-cell': {
                                        padding: '10px !important', // Force padding
                                        boxSizing: 'border-box', // Ensure padding is included in size
                                    },
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold', // Apply bold style to column header text
                                        whiteSpace: "normal",
                                    },
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none', // Removes the focus outline (border)
                                    },
                                }}
                            />
                        </div>
                    </Box>
                </Container>
            </div>
        </div>
    )
}

const getAnswer = (params) => {
    const item = params.row;
    return (item.type === "multiple-choice") ? (<ol type="A">
        {params.value ? params.value.map(choice => (<li style={{padding: '1px 0'}}>{choice}</li>) ) : <div></div>}
    </ol>) : (item.type === "free-form") ? item.answer : ""
}

export default Challenges;
