const { isAuthenticated, getToken } = require('components/auth/AuthService')
const utils = require("utils/utils")
const {generateRandomHex} = require("./utils");
//const crypto = require("crypto")

function getHeadersUnauthenticated() {
    const timestamp = Math.floor(Date.now() / 1000)
    const nonce = crypto.randomBytes(20).toString('hex')
    const iv = crypto.randomBytes(8).toString('hex')
    const key = "Efx2Si31aHt6wls0" // process.env.REACT_APP_API_KEY
    let message = "1234567890123456+" + timestamp + "+" + nonce // TODO??
    var token = utils.encrypt(key, iv, message)

    return {
        'Content-Type': 'application/json',
        'SP-Timestamp': timestamp,
        'SP-IV': iv,
        'SP-Token': token,
        'SP-LoginType': "local",
        'SP-Nonce': nonce,
    }
}

function getHeaders() {
    const uid = isAuthenticated()
    const idToken = getToken()

    console.log("++++ UID: " + uid)
    const timestamp = Math.floor(Date.now() / 1000)
    const nonce = generateRandomHex(20)
    const iv = generateRandomHex(8)
    //const key = process.env.REACT_APP_API_KEY
    //let message = "1234567890123456+" + timestamp + "+" + nonce // TODO??
    //var token = utils.encrypt(key, iv, message)

    return {
        'Content-Type': 'application/json',
        'SP-Timestamp': timestamp,
        'SP-IV': iv,
        'SP-Token': idToken,
        'SP-LoginType': "local",
        'SP-Email': uid,
        'SP-UniqueId': uid, // TODO: should be a client specific unique ID not user's id
        'SP-Nonce': nonce,
    }
}

function getHeadersWithAuthentication() {
    const timestamp = Math.floor(Date.now() / 1000)
    const nonce = generateRandomHex(20)
    const iv = generateRandomHex(8)
    let userId = window.sessionStorage.getItem("userId")
    let token = window.sessionStorage.getItem("auth.token")

    return {
        'Content-Type': 'application/json',
        'SP-Timestamp': timestamp,
        'SP-IV': iv,
        'SP-Token': token,
        'SP-LoginType': "local",
        'SP-Email': userId,
        'SP-UniqueId': userId,
        'SP-Nonce': nonce,
    }
}

module.exports = {
    getHeaders: getHeaders,
    getHeadersUnauthenticated: getHeadersUnauthenticated,
    getHeadersWithAuthentication: getHeadersWithAuthentication,
}