//const crypto = require("crypto")

const {styled} = require("@mui/material/styles")
const {TextField} = require("@mui/material")

function isProduction() {
    return (process.env.NODE_ENV === "production" || process.env.REACT_APP_NODE_ENV === "production")
}

function cloneObject(obj) {
    const clone = {};
    for (const i in obj) {
        if (typeof (obj[i]) == "object" && obj[i] != null) {
            clone[i] = cloneObject(obj[i])
        } else {
            clone[i] = obj[i]
        }
    }

    return clone
}

function printObject(obj) {
    for (const key in obj) {
        console.log(key + " -> " + obj[key])
    }
}

function encrypt(key, iv, message) {
    let cipher = crypto.createCipheriv('aes128', key, iv)
    let encrypted = cipher.update(message, "utf8", "base64")
    encrypted += cipher.final("base64")
    return encrypted
}

const generateNonceBase64 = (length) => {
    // Generate 16 random bytes and encode it as a base64 string
    return window.crypto.getRandomValues(new Uint8Array(length))
        .reduce((nonce, byte) => nonce + String.fromCharCode(byte), '')
        .replace(/[\u0000-\u001F\u007F-\u009F]/g, ''); // Remove control characters
}

const generateRandomHex = (length) => {
    return Array.from(window.crypto.getRandomValues(new Uint8Array(length)))
        .map(byte => byte.toString(16).padStart(2, '0'))  // Convert byte to hex and pad with '0'
        .join('');
}

const randomString = function (length) {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let randomString = '';
    for (let i = 0; i < length; i++) {
        const index = (Math.floor(Math.random() * 100)) % charset.length
        randomString += charset[index]
    }
    return randomString
}

const isEmailValid = (emailAddress) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailAddress).toLowerCase());
}

const validateEmail = (emailAddress) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(emailAddress)
}

const ResizableTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        resize: 'vertical', // Allows vertical resizing
        overflow: 'auto',   // Ensures scrolling works if content overflows
    },
})

module.exports = {
    encrypt: encrypt,
    isProduction: isProduction,
    cloneObject: cloneObject,
    printObject: printObject,
    randomString: randomString,
    isEmailValid: isEmailValid,
    generateRandomHex: generateRandomHex,
    validateEmail: validateEmail,
    ResizableTextField: ResizableTextField,
}
