import React, {useState} from 'react'
import {
    Box,
    Button, CircularProgress, FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material'
import {json, useLocation, useNavigate, useParams} from "react-router-dom"
import {addChallenge, deleteChallenge} from "./utils/ServerAPI"
import {ResizableTextField} from "./utils/utils"
import TagsSelection from "./components/TagsSelection"
import MarkdownEditor from "./components/MarkdownEditor"

const EditChallenge = () => {
    const { challenge } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const emptyChoices = ["", "", "", ""]
    let mode
    let parsedChallenge = null
    if (challenge === undefined) {
        mode = "add"
        parsedChallenge = {
            title: "",
            answer: "",
            choices: emptyChoices,
            correctAnswer: -1,
            tags: [],
            level: 1,
            type: "multiple-choice",
        }
    } else {
        mode = "edit"
        const decodedChallenge = decodeURIComponent(challenge)
        parsedChallenge = JSON.parse(decodedChallenge)
        console.log("+++++ PPPP: " + JSON.stringify(parsedChallenge))
    }

    const storageItemKey = 'savedChallengeData'
    if (mode === "add") {
        const savedFormData = JSON.parse(localStorage.getItem(storageItemKey))
        if (savedFormData) {
            parsedChallenge = savedFormData
        }
    } else {
        localStorage.removeItem(storageItemKey)
    }

    const types = ["multiple-choice", "free-form"]

    const [saveInProgress, setSaveInProgress] = useState(false)
    const [title, setTitle] = useState(parsedChallenge.title)
    const [answer, setAnswer] = useState(parsedChallenge.answer)
    const [notes, setNotes] = useState(parsedChallenge.notes)
    const [level, setLevel] = useState(parsedChallenge.level)
    const [choices, setChoices] = useState(parsedChallenge.choices)
    const [choiceSelection, setChoiceSelection] = useState(parsedChallenge.correctAnswer)
    const [tags, setTags] = useState(parsedChallenge.tags)
    const [type, setType] = useState(parsedChallenge.type)

    const handleSave = async () => {
        try {
            setSaveInProgress(true)

            const updatedChallenge = parsedChallenge
            updatedChallenge.title = title
            if (!updatedChallenge.data) {
                updatedChallenge.data = {}
            }
            updatedChallenge.type = type
            if (updatedChallenge.type === "free-form") {
                updatedChallenge.data.answer = {
                    text: answer,
                }
            } else if (updatedChallenge.type === "multiple-choice") {
                updatedChallenge.data.choices = choices
                updatedChallenge.data.correctAnswer = choiceSelection
            }

            updatedChallenge.level = level
            updatedChallenge.tags = tags
            updatedChallenge.notes = notes
            delete updatedChallenge.id
            console.log("Saving challenge: " + JSON.stringify(updatedChallenge))

            addChallenge(updatedChallenge, function(error) {
                if (error) {
                    console.error("Failed to save challenge: " + error.message)
                    if (error.data.code === "401") {
                        if (updatedChallenge.type === "free-form") {
                            updatedChallenge.answer = answer
                        } else if (updatedChallenge.type === "multiple-choice") {
                            updatedChallenge.choices = choices
                            updatedChallenge.correctAnswer = choiceSelection
                        }
                        localStorage.setItem(storageItemKey, JSON.stringify(updatedChallenge))
                        navigate('/signin', { state: { from: location } })
                    } else {
                        alert("Failed to save challenge: " + error)
                    }
                } else {
                    navigate(-1)
                }
            })

            setSaveInProgress(false)
        } catch (error) {
            localStorage.removeItem(storageItemKey)
            setSaveInProgress(false)
            console.error('Error updating document:', error)
        }
    }

    const handleLevelChange = (event) => {
        const newValue = event.target.value
        if (newValue === '' || (newValue >= 1 && newValue <= 5)) {
            setLevel(newValue)
        }
    }

    const handleDelete = async () => {
        try {
            setSaveInProgress(true)
            console.log("Deleting challenge: " + parsedChallenge.title)

            deleteChallenge(parsedChallenge.id, function(error) {
                if (error) {
                    console.error("Failed to delete challenge: " + error.message)
                    if (error.data.code === "401") {
                        navigate("/signin")
                    } else {
                        alert("Failed to delete challenge: " + error)
                    }
                } else {
                    console.log("Delete response: " + JSON.stringify(json))
                    navigate(-1)
                }
            })

            setSaveInProgress(false)
        } catch (error) {
            setSaveInProgress(false)
            console.error('Error deleting document:', error)
        }
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    const handleAnswerChange = (event) => {
        setAnswer(event.target.value)
    }

    const handleNotesChange = (value) => {
        setNotes(value)
    }

    const handleChoiceSelectionChange = (event) => {
        setChoiceSelection(event.target.value)
    }

    const handleLabelChange = (index) => (event) => {
        const updatedChoices = [...choices]
        updatedChoices[index] = event.target.value
        setChoices(updatedChoices)
    }

    const handleTypeChange = (event) => {
        event.preventDefault()
        if (types[event.target.value] === "free-form" && choices === undefined) {
            setChoices(emptyChoices)
        }
        setType(types[event.target.value])
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh', width: '90vw'}}>
            <div
                style={{
                    padding: '20px',
                    borderBottom: '1px solid #ddd',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h4">{mode === "add" ? "Add" : "Edit"} Challenge</Typography>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={saveInProgress}
                    startIcon={saveInProgress ? <CircularProgress size={20} /> : null}>
                    {mode === "add" ? "Add" : "Save"}
                </Button>

                {(mode === "edit") && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDelete}
                        disabled={saveInProgress}
                        startIcon={saveInProgress ? <CircularProgress size={20} /> : null}>
                        Delete
                    </Button>
                )}
            </div>

            <div style={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '20px'}}>
                <TextField label="title" value={title} onChange={handleTitleChange}/>

                <TagsSelection
                    acceptAny={true}
                    initialTags={tags}
                    onTagsChange={(tags) => {
                        console.log("Selected Tags:", tags)
                        setTags(tags)
                    }}
                />

                <TextField
                    label="Enter level of difficulty"
                    type="number"
                    value={level}
                    onChange={handleLevelChange}
                    inputProps={{min: 1, max: 5}}
                    helperText="Value must be between 1 and 5"
                />

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography style={{marginRight: '16px'}}>Select challenge type</Typography>
                    <RadioGroup row value={types.indexOf(type)} onChange={handleTypeChange}>
                        {
                            types.map((choice, index) => {
                                return (
                                    <div>
                                        <Radio value={index} size="small"/>
                                        {types[index]}
                                    </div>)
                            })
                        }
                    </RadioGroup>
                </div>

                {type === "multiple-choice" &&
                    <FormControl component="fieldset">
                        <FormLabel sx={{marginBottom: '16px'}}>List answers and choose the correct one</FormLabel>
                        <RadioGroup value={choiceSelection} onChange={handleChoiceSelectionChange}>
                            {
                                (choices || emptyChoices).map((choice, index) => {
                                    return <Box display="flex" alignItems="center">
                                        <Radio value={index} size="small"/>
                                        <TextField
                                            value={choices === undefined ? "" : choices[index]}
                                            onChange={handleLabelChange(index)}
                                            size="small"
                                            variant="standard"
                                            style={{marginLeft: 4, width: '100%'}}
                                        />
                                    </Box>
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                }

                {type === "free-form" &&
                    <ResizableTextField
                        label="answer"
                        value={answer}
                        onChange={handleAnswerChange}
                        multiline={true}
                        minRows={5}
                        maxRows={20}
                        InputProps={{
                            style: {resize: 'vertical'} // Makes only the bottom resizable
                        }}
                    />
                }

                <MarkdownEditor
                    label="notes"
                    value={notes || ""}
                    onChange={handleNotesChange}
                />
            </div>
        </div>
    )
}

export default EditChallenge
