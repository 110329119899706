import {Alert, Snackbar} from "@mui/material"

const QuizSnackbar = ({error, setError}) => {
    const closeSnackbar = () => {
        setError("")
    }

    return (
        <Snackbar
            open={error.length > 0}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ width: '100%', paddingTop: '40px' }}
        >
            <Alert
                onClose={closeSnackbar}
                severity="error"
                style={{ width: '100%' }}
                // action={
                //     <Button color="inherit" size="small" onClick={}>
                //         Dismiss
                //     </Button>
                // }
            >
                {error}
            </Alert>
        </Snackbar>
    )
}

export default QuizSnackbar
