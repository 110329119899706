import React, {useContext, useState} from 'react'
import {AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Box} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import {Link, useNavigate} from 'react-router-dom'
import {AuthContext} from "./components/auth/AuthProvider"
import {isAuthenticated, logout} from "./components/auth/AuthService"

const DrawerMenu = () => {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const navigate = useNavigate()

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open)
    }

    const handleSignOut = () => {
        logout().then(() => {
            navigate("/signin")
        })
    }

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemText primary="Home" />
                </ListItem>
                {!isAuthenticated() ?
                    <ListItem button component={Link} to="/signin">
                        <ListItemText primary="Sign In"/>
                    </ListItem>
                    : ""
                }
                {isAuthenticated() ?
                    <ListItem button component={Link} to="/challenges">
                        <ListItemText primary={isAdmin ? "Questions" : "My Questions"}/>
                    </ListItem>
                    : ""
                }
                {isAuthenticated() && isAdmin?
                    <ListItem button component={Link} to="/fetch-challenges">
                        <ListItemText primary={"Fetch Challenges"}/>
                    </ListItem>
                    : ""
                }
                {isAuthenticated() ?
                    <ListItem button component={Link} to="/profile">
                        <ListItemText primary="Profile" />
                    </ListItem>
                    : ""
                }
                {isAuthenticated() ?
                    <ListItem button onClick={handleSignOut}>
                        <ListItemText primary="Sign Out" />
                    </ListItem>
                    : ""
                }
            </List>
        </div>
    )

    const { currentUser, isAdmin } = useContext(AuthContext)

    let displayName = currentUser ? currentUser.providerData[0].displayName : ""
    if (currentUser && isAdmin) {
        displayName = displayName + " (admin)"
    }

    return (
        <div>
            <AppBar position="static">
                <Toolbar style={{ backgroundColor: '#3f51b5' }}>
                    <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)} aria-label="menu">
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6">
                        Quiz App
                    </Typography>

                    {/* Spacer to push text to the right */}
                    <Box sx={{ flexGrow: 1 }} />

                    {/* Text at the far right */}
                    <Typography variant="body1" color="inherit">
                        {displayName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </div>
    )
}

export default DrawerMenu
