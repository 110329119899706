// src/authService.js
import { auth } from 'config/firebase-config'
import {createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, updateProfile} from 'firebase/auth';

onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setAuthenticated(user.uid, user.email)
        user.getIdToken(true).then(function(idToken) {
            setToken(idToken)
        })
    } else {
        // User is signed out
        clearAuthenticated()
    }
})

export const setAuthenticated = (uid, email) => {
    localStorage.setItem("auth.uid", uid)
    localStorage.setItem("auth.email", email)
}

export const setToken = (token) => {
    localStorage.setItem("auth.token", token)
    window.sessionStorage.setItem("auth.token", token)
}

export const getToken = () => {
    return localStorage.getItem("auth.token")
}

export const isAuthenticated = () => {
    return localStorage.getItem("auth.uid")
}

const clearAuthenticated = () => {
    localStorage.removeItem("auth.email")
    localStorage.removeItem("auth.uid")
    localStorage.removeItem("auth.token")
}

export const getUserEmailAddress = () => {
    return localStorage.getItem("auth.email")
}

export const signup = (email, password, callback) => {
    return createUserWithEmailAndPassword(auth, email, password).then((credential) => {
        const user = credential.user
        setAuthenticated(user.uid, user.email)
        user.getIdToken(true).then(function(idToken) {
            setToken(idToken)
            callback(user)
        })
    })
};

export const login = (email, password, callback) => {
    signInWithEmailAndPassword(auth, email, password).then((credential) => {
        const user = credential.user
        setAuthenticated(user.uid, user.email)
        user.getIdToken(true).then(function(idToken) {
            setToken(idToken)
            callback(null, user)
        })
    })
        .catch((error) => {
            callback(error)
        })
}

export const updateUserProfile = (user, displayName, callback) => {
    updateProfile(user, {displayName: displayName}).then(() => {
        callback()
    })
 }

export const logout = () => {
    return signOut(auth)
}

export const getUserId = () => {
    return isAuthenticated()
}
