import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import {isAuthenticated} from "./auth/AuthService"

const ProtectedRoute = ({ children }) => {
    const location = useLocation()

    if (location.pathname === '/signin'
        || location.pathname === '/signup'
        || location.pathname === '/forgot-password') {
        return children
    }

    if (!isAuthenticated()) {
        return <Navigate to="/signin" />
    }

    return children
}

export default ProtectedRoute
