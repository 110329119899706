import React, {useRef, useState} from "react"
import Slider from "react-slick"
import {QuizPage} from "./QuizPage"
import {Button} from "@mui/material"

export const HorizontalPager = ({challenges, setFinished}) => {
    const sliderRef = useRef(null)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [answers, setAnswers] = useState(new Array(challenges.length).fill(-1))

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    }

    const finishClicked = (event) => {
        event.preventDefault()
        setFinished(answers)
    }

    return (
        <div style={{width: '100%'}}>
            <Slider ref={sliderRef} {...settings}>
                {
                    challenges.map((challenge, index) => {
                        return (
                            <QuizPage
                                challenge={challenge} index={index} count={challenges.length}
                                answers={answers} setAnswers={setAnswers} settings={settings}/>
                        )
                    })
                }
            </Slider>

            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    paddingTop: '20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {(currentSlide < challenges.length - 1) ?
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={answers[currentSlide] === -1}
                        onClick={() => sliderRef.current.slickNext()}>
                        Next
                    </Button>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={answers[currentSlide] === -1}
                        onClick={finishClicked}>
                        Grade
                    </Button>
                }
            </div>
        </div>
    )
}