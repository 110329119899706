import React, {useContext} from "react"
import {AuthContext} from "./components/auth/AuthProvider"

const Profile = () => {
    const { currentUser, isAdmin } = useContext(AuthContext)

    return (
        <div>
            <b>Profile</b>
            <hr/>
            <div>Name: {currentUser ? (currentUser.displayName || currentUser.username) : null}</div>
            <div>Admin? {isAdmin.toString()}</div>
            {/*<div>UID: {currentUser ? (currentUser.uid) : null}</div>*/}
        </div>
    )
}

export default Profile