import React, { useState } from "react";
import {TextField, Button, Box, Typography} from "@mui/material";
import {login} from "./AuthService";
import {useNavigate, Link, useLocation} from "react-router-dom";

const SignInForm = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()

    const handleSubmit = (event) => {
        event.preventDefault()
        login(email, password, ((error, user) => {
            if (error) {
                setError("Login failed: " + error.message)
            } else {
                setError(null)
                if (user.email) {
                    const from = location.state?.from?.pathname || "/"
                    console.log("++++ REDIRECT AFTER SIGNIN STATE: " + JSON.stringify(location.state))
                    console.log("++++ REDIRECT AFTER SIGNIN FROM: " + from)

                    if (from && from.length > 0) { //(redirectUrl && redirectUrl.length > 0) {
                        navigate(from)
                    } else {
                        navigate("/")
                    }
                }
            }
        }))
    }

    return (
        <Box
            sx={{
                height: '80vh',
                width: '80vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    p: 4,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>
                    Sign In
                </Typography>
                <TextField
                    label="Email address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                />
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                />
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{marginTop: '20px', padding: '10px'}}>
                    Sign In
                </Button>
                {error && <div style={{ color: "red", paddingTop: "10px" }}>{error}</div>}
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', paddingTop: '20px'}}>
                    <Link to="/signup">Create new account</Link>
                    <Link to="/forgot-password">Forgot password?</Link>
                </div>
            </Box>
        </Box>
    )
}

export default SignInForm
