const utils = require("utils/utils")

module.exports = (utils.isProduction()) ?
    {
        //hostname: "quizdb-server.herokuapp.com",
        hostname: "service.sandymist.com",
        port: 7930,
        useHTTPS: true,
        get webhostname() { return "https://" + this.hostname },
    } :
    {
        hostname: "localhost",
        port: 7931,
        useHTTPS: false,
        get webhostname() { return "http://" + this.hostname + ":" + this.port },
    }
