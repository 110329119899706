import React from 'react'
import { Box, Typography } from '@mui/material'
import { marked } from 'marked'
import {ResizableTextField} from "../utils/utils"

const MarkdownEditor = ({ label, value, onChange }) => {

    const handleChange = (event) => {
        const newValue = event.target.value
        onChange(newValue)
    }

    const renderMarkdown = () => {
        return { __html: marked(value) }
    }

    return (
        <Box>
            {/* TextField for Markdown input */}
            <ResizableTextField
                label={label}
                multiline
                minRows={5}
                maxRows={20}
                fullWidth
                value={value}
                onChange={handleChange}
                InputProps={{
                    style: {resize: 'vertical'} // Makes only the bottom resizable
                }}
            />

            {/* Markdown preview */}
            {value.length > 0 &&
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6">Preview</Typography>
                    <div
                        dangerouslySetInnerHTML={renderMarkdown()}
                        style={{
                            padding: '10px',
                            backgroundColor: '#f4f4f4',
                            borderRadius: '8px',
                        }}
                    />
                </Box>
            }
        </Box>
    )
}

export default MarkdownEditor
