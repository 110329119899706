import React from 'react'

const CheckboxGroup = ({options, selectedOptions, setSelectedOptions}) => {
    const handleCheckboxChange = (event) => {
        const { value } = event.target

        setSelectedOptions((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((option) => option !== value) // Remove if already selected
                : [...prevSelected, value] // Add to selected
        )
    }

    return (
        <div >
            {options.map((option) => (
                <label key={option.id} style={{paddingLeft: '20px'}}>
                    <input
                        type="checkbox"
                        value={option.id}
                        checked={selectedOptions.includes(option.id)}
                        onChange={handleCheckboxChange}
                    />
                    &nbsp;{option.label}
                </label>
            ))}
        </div>
    )
}

export default CheckboxGroup
