const config = require("../config/server-config")
const {useHTTPS} = require("../config/server-config");
const {logout} = require("../components/auth/AuthService");
const {getHeaders} = require("./network");

const getUrl = (path) => {
    return ((useHTTPS) ? "https://" : "http://") + config.hostname + ":" + config.port + path
}

const getRequest = (path, callback) => {
    const url = getUrl(path)
    console.log("GET: " + url)

    fetch(
        url,
        {
            method: 'GET',
            headers: getHeaders(),
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "failed" && json.code === "401") {
                logout()
                const error = Error("Requires authentication")
                error.data = {status: json.status, code: json.code}
                throw error
            }
            //console.log("++++ GET REQ RESP: " + JSON.stringify(json))
            callback(null, json)
        })
        .catch((error) => {
            console.error("Error on GET: Path: " + path + ", error: " + error)
            callback(error)
        })
}

const postRequest = (path, body, callback) => {
    const url = getUrl(path)
    console.log("POST: " + url)

    fetch(
        url,
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(body)
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "failed" && json.code === "401") {
                logout()
                const error = Error("Requires authentication")
                error.data = {status: json.status, code: json.code}
                throw error
            }
            console.log("++++ POST REQ RESP: " + JSON.stringify(json));
            callback(null, json)
        })
        .catch((error) => {
            console.error("++++ POST REQ ERROR: " + JSON.stringify(error))
            callback(error)
        })
}

const deleteRequest = (path, callback) => {
    const url = getUrl(path)
    console.log("DELETE: " + url)

    fetch(
        url,
        {
            method: 'DELETE',
            headers: getHeaders(),
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "failed" && json.code === "401") {
                logout()
                const error = Error("Requires authentication")
                error.data = {status: json.status, code: json.code}
                throw error
            }
            console.log("++++ Delete response: " + JSON.stringify(json));
            callback(null, json)
        })
        .catch((error) => {
            console.error("++++ Delete error: " + error);
            callback(error)
        })
}

const getTags = (prefix, callback) => {
    getRequest("/tags?prefix=" + prefix, callback)
}

// challenge
//
const addChallenge = (challenge, callback) => {
    postRequest("/challenge", challenge, callback)
}

const getChallenge = (id, callback) => {
    getRequest("/challenge?id=" + id, callback)
}

const fetchChallenges = (tags, limit, callback) => {
    const body = {
        tags: tags,
        limit: limit,
    }
    postRequest("/ai-challenges", body, callback)
}

const getAllChallenges = (tags, searchString, callback) => {
    getRequest("/challenges?tags=" + tags + "&search=" + searchString, callback)
}

const getMyChallenges = (tags, searchString, callback) => {
    getRequest("/my-challenges?tags=" + tags + "&search=" + searchString, callback)
}

const deleteChallenge = (id, callback) => {
    deleteRequest("/challenge?id=" + id, callback)
}

// session
//
const addSession = (tags, types, maxLevel, limit, callback) => {
    const body = {
        tags: tags,
        types: types,
        maxLevel: maxLevel,
        limit: limit,
    }
    postRequest("/session", body, callback)
}

const gradeSession = (id, answers, callback) => {
    const body = {
        id: id,
        answers: answers,
    }
    postRequest("/grade", body, callback)
}

module.exports = {
    addSession: addSession,
    gradeSession: gradeSession,
    addChallenge: addChallenge,
    getChallenge: getChallenge,
    getAllChallenges: getAllChallenges,
    getMyChallenges: getMyChallenges,
    fetchChallenges: fetchChallenges,
    deleteChallenge: deleteChallenge,
    getTags: getTags,
}
