import React, {createContext, useEffect, useState} from 'react'
import {onAuthStateChanged} from 'firebase/auth'
import {auth} from 'config/firebase-config'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        setLoading(true)
        return onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            if (user) {
                user.getIdTokenResult(true)
                    .then((idTokenResult) => {
                        if (idTokenResult.claims.admin) {
                            console.log("User " + user.email + " is an admin")
                            setIsAdmin(true)
                        } else {
                            setIsAdmin(false)
                        }
                        setLoading(false)
                    })
                    .catch((error) => {
                        setIsAdmin(false)
                        console.log('Error retrieving claims:', error)
                        setLoading(false)
                    })
            } else {
                setLoading(false)
            }
        }) // Unsubscribe from listener when component unmounts
    }, [])

    return (
        <AuthContext.Provider value={{ currentUser, isAdmin }}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
