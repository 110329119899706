import React, { useState } from "react";
import {TextField, Button, Box, Typography, useTheme} from "@mui/material";
import {signup, updateUserProfile} from "./AuthService";
import {Link, useNavigate} from "react-router-dom";
import {isEmailValid, validateEmail} from "../../utils/utils";

const SignUpForm = () => {
    const [displayName, setDisplayName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const navigate = useNavigate()
    const theme = useTheme()
    const defaultBorderColor = theme.palette.grey[300]
    const [borderColor, setBorderColor] = useState(defaultBorderColor)
    const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false)
    const [error, setError] = useState("")

    const handleSubmit = (event) => {
        event.preventDefault()
        const passwordMatched = (password.length > 0 && (password.length === confirmPassword.length))
        if (!passwordMatched || displayName.length < 4 || !validateEmail(email)) {
            return
        }
        signup(email, password, (user => {
            if (user.email) {
                updateUserProfile(user, displayName, function () {
                    navigate("/")
                })
            }
        }))
            .then((e) => {
                setError(null)
            })
            .catch(error => {
                console.log("Signup error: " + error.message)
                setError(error.message)
            })
    }

    const isNameValid = (name) => { return name.length > 3 }

    const nameUpdated = (event) => {
        event.preventDefault()
        setDisplayName(event.target.value)
        validateForm(event.target.value, email, password, confirmPassword)
    }

    const emailAddressUpdated = (event) => {
        event.preventDefault()
        setEmail(event.target.value)
        validateForm(displayName, event.target.value, password, confirmPassword)
    }

    const isPasswordValid = (p, c) => { return (p.length > 6 && (p.length === c.length)) }

    const passwordUpdated = (p, c) => {
        setBorderColor(isPasswordValid(p, c) ? defaultBorderColor : 'red')
        validateForm(displayName, email, p, c)
    }

    const validateForm = (n, e, p, c) => {
        const isValid = isNameValid(n) && isEmailValid(e) && isPasswordValid(p, c)
        setSubmitButtonEnabled(isValid)
    }

    return (
        <Box
            sx={{
                height: '80vh',
                width: '80vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    p: 4,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>
                    Sign Up
                </Typography>
                <TextField
                    label="Name"
                    type="text"
                    value={displayName}
                    onChange={nameUpdated}
                    fullWidth
                    margin="normal"
                    required
                />
                <TextField
                    label="Email address"
                    type="email"
                    value={email}
                    onChange={emailAddressUpdated}
                    fullWidth
                    margin="normal"
                    required
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                        passwordUpdated(e.target.value, confirmPassword)
                    }}
                    fullWidth
                    margin="normal"
                    required
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: borderColor },
                        },
                        '&:hover fieldset': {
                            borderColor: { borderColor: borderColor },
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: { borderColor: borderColor },
                        },
                    }}
                />
                <TextField
                    label="Confirm password"
                    type="password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value)
                        passwordUpdated(password, e.target.value)
                    }}
                    fullWidth
                    margin="normal"
                    required
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: borderColor },
                        },
                        '&:hover fieldset': {
                            borderColor: { borderColor: borderColor },
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: { borderColor: borderColor },
                        },
                    }}
                />
                <Button
                    type="submit"
                    variant="contained" color="primary" fullWidth sx={{marginTop: '20px', padding: '10px'}}
                    disabled={!submitButtonEnabled}
                >
                    Sign Up
                </Button>
                {error && <div style={{ color: "red", paddingTop: "10px" }}>{error}</div>}
                <div style={{display: 'flex', justifyContent: 'left', width: '100%', paddingTop: '20px'}}>
                    Already registered? <Link to="/signin">&nbsp;Sign In</Link>
                </div>
            </Box>
        </Box>
    )
}

export default SignUpForm
