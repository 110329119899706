import React, {useState} from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {Box, Button, CircularProgress, Container, TextField, Typography} from "@mui/material"
import {addSession, gradeSession} from "./utils/ServerAPI"
import {useNavigate} from "react-router-dom"
import TagsSelection from "./components/TagsSelection"
import {QuizState} from "./QuizState"
import {HorizontalPager} from "./HorizontalPager"
import CheckboxGroup from "./components/CheckboxGroup"
import QuizSnackbar from "./components/QuizSnackbar"

const Quiz = () => {
    const navigate = useNavigate()
    const [tags, setTags] = useState([])
    const [challenges, setChallenges] = useState([])
    const [answers, setAnswers] = useState([])
    const [quizState, setQuizState] = useState(QuizState.SELECTION)
    const [sessionId, setSessionId] = useState('')
    const [grade, setGrade] = useState(0)
    const [limit, setLimit] = useState(10)
    const [selectedTypes, setSelectedTypess] = useState([])
//    const [practiceMode, setPracticeMode] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const types = [
        { id: 'multiple-choice', label: 'Multiple choice' },
        { id: 'free-form', label: 'Free form' },
    ]

    const createSession = (tags, limit, callback) => {
        if (tags.length === 0) {
            callback(null, [])
            return
        }

        addSession(tags, selectedTypes, 5, limit, function(error, data) {
            if (error) {
                console.error("Failed to get challenges: " + JSON.stringify(error))
                callback(error)
            } else {
                let items = []
                setSessionId(data.data.id)
                data.data.challenges.forEach((item) => {
                    item.id = item._id
                    if (item.type === "multiple-choice") {
                        item.choices = item.data.choices
                        items.push(item)
                    } else if (item.type === "free-form") {
                        items.push(item)
                    }
                })
                callback(null, items)
            }
        })
    }

    const postGrade = async (selections) => {
        await gradeSession(sessionId, selections, function(error, results) {
            if (error) {
                console.error("Failed to post grade: " + JSON.stringify(error))
            } else {
                console.log("GRADE RESULTS: " + JSON.stringify(results))
                setGrade(results.data.score.grade)
                setQuizState(QuizState.RESULTS)
            }
        })
    }

    const startClicked = (event) => {
        event.preventDefault()
        setInProgress(true)
        createSession(tags, limit, function(error, data) {
            setInProgress(false)
            if (error) {
                if (error.data && error.data.code === "401") {
                    navigate("/signin")
                } else {
                    setErrorMessage("Error: " + error.data)
                }
            } else {
                if (data.length === 0) {
                    alert("No questions!")
                } else {
                    setChallenges(data)
                    setQuizState(QuizState.QUIZ)
                }
            }
        })
    }

    const setFinished = (selections) => {
        setAnswers(selections)
        postGrade(selections.map((item) => {
            return item
        }))
    }

    // const handlePracticeModeChange = (event) => {
    //     setPracticeMode(event.target.checked)
    // }

    const handleLimitChange = (event) => {
        const newValue = event.target.value
        if (newValue === '' || (newValue >= 1 && newValue <= 10)) {
            setLimit(newValue)
        }
    }

    const quizSetup = (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
            <Box mb={2}>
                <Box display="flex" justifyContent="center" alignItems="top">
                    <Typography variant="h4">Start Your Quiz</Typography>
                </Box>
            </Box>

            <TagsSelection
                acceptAny={true}
                onTagsChange={(tags) => {
                    console.log("Selected Tags:", tags)
                    setTags(tags)
                }}
            />

            <CheckboxGroup options={types} selectedOptions={selectedTypes} setSelectedOptions={setSelectedTypess}/>

            {/*<SingleSwitch label={"Practice mode"} checked={practiceMode} onChange={handlePracticeModeChange}/>*/}

            <TextField
                label="Maximum number of questions"
                type="number"
                value={limit}
                onChange={handleLimitChange}
                inputProps={{min: 1, max: 10}}
                helperText="Value must be between 1 and 10"
                sx={{marginTop: '24px'}}
            />

            <Button
                variant="contained"
                color="primary"
                sx={{marginTop: '16px'}}
                disabled={selectedTypes.length === 0 || tags.length === 0 || inProgress}
                startIcon={inProgress ? <CircularProgress size={20} /> : null}
                onClick={startClicked}>
                Start
            </Button>
        </div>
    )

    const quizPager = (
        <div>
            {tags.length > 0 &&
                <HorizontalPager challenges={challenges} setFinished={setFinished}/>
            }
        </div>
    )

    const quizHomeClicked = (event) => {
        event.preventDefault()
        reset()
    }

    const reset = () => {
        setTags([])
        setChallenges([])
        setAnswers([])
        setQuizState(QuizState.SELECTION)
        setSessionId('')
        setGrade(0)
    }

    const getScore = (grade, challenges) => {
        console.log("Grade: " + grade)
        return (grade * 100) + "%"
    }

    const results = (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
            Finished!
            <hr/>
            <Typography>
                Score: {getScore(grade, challenges)}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{marginTop: '16px'}}
                onClick={quizHomeClicked}>
                Back to Home
            </Button>
        </div>
    )

    return (
        <Container maxWidth="lg">
            <QuizSnackbar error={errorMessage} setError={setErrorMessage} />

            {(quizState === QuizState.SELECTION) ?
                quizSetup
                : ((quizState === QuizState.QUIZ) ?
                quizPager : results)
            }
        </Container>
    )
}

export default Quiz
