import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAhC4DhY5cmWmDZkuwF1D-quEE3GLnWLAQ",
  authDomain: "quizdb-7fbdb.firebaseapp.com",
  projectId: "quizdb-7fbdb",
  storageBucket: "quizdb-7fbdb.appspot.com",
  messagingSenderId: "541547504750",
  appId: "1:541547504750:web:b511b04f4a79302597f27f",
  measurementId: "G-0RRXDH4CFR"
}

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

export { db, auth };
