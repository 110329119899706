import React, {useState} from "react";
import {Box, FormControl, Radio, RadioGroup} from "@mui/material";
import {ResizableTextField} from "./utils/utils";

export const QuizPage = ({challenge, index, count, setAnswers}) => {
    const [choiceSelection, setChoiceSelection] = useState(-1)
    const [answer, setAnswer] = useState("")

    const handleAnswerChange = (event) => {
        if (challenge.type === "multiple-choice") {
            setChoiceSelection(event.target.value)
        } else if (challenge.type === "free-form") {
            setAnswer(event.target.value)
        }
        setAnswers(prevAnswers => {
            const updatedAnswers = [...prevAnswers]
            updatedAnswers[index] = event.target.value
            return updatedAnswers
        })
    }

    return (
        <div style={{
            display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
            height: '50vh', padding: '10px',
            //border: '1px solid #ddd'
        }}>
            <h4>{challenge.title} ({index + 1} of {count})</h4>
            {challenge.type === "multiple-choice" &&
                <FormControl component="fieldset" sx={{display: 'inline-block'}}>
                    <RadioGroup value={choiceSelection} onChange={handleAnswerChange}>
                        {
                            challenge.choices.map((choice, index) => {
                                return <Box display="flex" alignItems="center">
                                    <Radio value={index} size="small"/>
                                    {challenge.choices[index]}
                                </Box>
                            })
                        }
                    </RadioGroup>
                </FormControl>
            }

            {challenge.type === "free-form" &&
                <ResizableTextField
                    label="answer"
                    value={answer}
                    onChange={handleAnswerChange}
                    multiline={true}
                    minRows={5}
                    maxRows={20}
                    sx={{width: '90%', height: '90%'}}
                    InputProps={{
                        style: {resize: 'vertical'} // Makes only the bottom resizable
                    }}
                />
            }
        </div>
    )
}