import React, {useState} from 'react'
import {Box, Button, CircularProgress, Container, TextField, Typography} from "@mui/material"
import TagsSelection from "./components/TagsSelection"
import QuizSnackbar from "./components/QuizSnackbar"
import {useNavigate} from "react-router-dom"
import {fetchChallenges} from "./utils/ServerAPI"

const FetchChallenges = () => {
    const navigate = useNavigate()
    const [tags, setTags] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [limit, setLimit] = useState(10)
    const [inProgress, setInProgress] = useState(false)
    const maxLimit = 50

    const fetchClicked = (event) => {
        event.preventDefault()
        setInProgress(true)
        fetchChallenges(tags, limit, function(error, data) {
            setInProgress(false)
            if (error) {
                if (error.data && error.data.code === "401") {
                    navigate("/signin")
                } else {
                    setErrorMessage("Error: " + error.data)
                }
            } else {
                if (data.length === 0) {
                    alert("No questions!")
                } else {
                    alert("Fetched!")
                }
            }
        })
    }

    const handleLimitChange = (event) => {
        const newValue = event.target.value
        if (newValue === '' || (newValue >= 1 && newValue <= maxLimit)) {
            setLimit(newValue)
        }
    }

    const fetch = (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
            <Box mb={2}>
                <Box display="flex" justifyContent="center" alignItems="top">
                    <Typography variant="h4">Fetch challenges</Typography>
                </Box>
            </Box>

            <TagsSelection
                acceptAny={true}
                onTagsChange={(tags) => {
                    console.log("Selected Tags:", tags)
                    setTags(tags)
                }}
            />

            <TextField
                label="Maximum number of challenges"
                type="number"
                value={limit}
                onChange={handleLimitChange}
                inputProps={{min: 1, max: maxLimit}}
                helperText={"Value must be between 1 and " + maxLimit}
                sx={{marginTop: '24px'}}
            />

            <Button
                variant="contained"
                color="primary"
                sx={{marginTop: '16px'}}
                disabled={tags.length === 0 || inProgress}
                startIcon={inProgress ? <CircularProgress size={20} /> : null}
                onClick={fetchClicked}>
                Start
            </Button>
        </div>
    )

    return (
        <Container maxWidth="lg">
            <QuizSnackbar error={errorMessage} setError={setErrorMessage} />

            {fetch}
        </Container>
    )
}

export default FetchChallenges
