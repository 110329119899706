import React from "react"

const ForgotPassword = () => {
    return (
        <div>
            <b>Forgot Password</b>
            <hr/>
            Not supported yet
        </div>
    )
}

export default ForgotPassword